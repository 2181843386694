//Specific JS for this skin
require(['jquery'], function ($) {
    if ($("#mini-cart").length > 0) {
     $("#mini-cart .icon.i-cart").removeClass("icon i-cart").addClass("fa fa-shopping-cart");
    }
    $('.form-search .button-search').html("<i class='fa fa-search'></i>");
    $(window).on("load", function() {
        const newh = $(window).outerHeight() - $('.header-container').outerHeight() - $('.main-container').outerHeight() - 	$('.footer-container').outerHeight();
        if (newh > 0) {
          $('.main-container').css('min-height', $('.main-container').outerHeight() + newh +'px');
        }
      });
});
